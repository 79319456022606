import React from 'react';
import { Spinner } from 'react-bootstrap';

const FullScreenLoader = () => {
  return (
    <div className={`d-flex justify-content-center align-items-center`} style={{ width: '100%', height: '75vh' }}>
      <Spinner animation="border" variant="success" style={{ width: '80px', height: '80px' }} />
    </div>
  );
};

export default FullScreenLoader;
