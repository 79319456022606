import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./Features/authSlice";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "auth",
  ],
};

const rootReducer = combineReducers({
  auth: authReducer,
  
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const finalReducer = (state, action) => {
  if (action.type === "LOGOUT/fulfilled" || action.type === "auth/logout") {
    state = {
      ...state,
      auth: undefined,
    };
    localStorage.removeItem("token");
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: finalReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
