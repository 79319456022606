export const SIDE_ITEMS = [
  {
    title: 'Front Matter',
    items: [],
  },
  {
    title: 'Chapters',
    items: [],
  },
  {
    title: 'Back Matter',
    items: [],
  },
];

export const AI_CONTEXT = [
  { name: 'Goals', value: 'goal_setting' },
  { name: 'Brainstorm', value: 'brainstorm' },
  { name: 'Research', value: 'research' },
  { name: 'Vocabulary', value: 'vocabulary' },
];

export const AI_CONTEXT_DATA = [
  {
    title: 'Goals',
    placeholder: 'Message Goal Setting coach....',
    suggestions: ['How many words should I write today?', 'Propose a story development goal', 'Pull up previous goals'],
  },
  {
    title: 'Brainstorm',
    placeholder: 'Message Brainstorming Buddy...',
    suggestions: [
      'Suggest plot twist possibilities',
      'Explore character background ideas',
      'Help me develop unique settings',
    ],
  },
  {
    title: 'Research',
    placeholder: 'Message Research Assistant...',
    suggestions: [
      'Suggest books with similar literary themes',
      'Find historical details for this era',
      'Summarize latest scientific findings',
    ],
  },
  {
    title: 'Vocabulary',
    placeholder: 'Message Vocabulary Expert...',
    suggestions: [
      'Suggest a word to replace anger',
      'Propose 5 unique adjectives for this scene',
      "What's a better word for nice?",
    ],
  },
];

export const NAVBAR_BUTTONS = ['Writing', 'Edit', 'Cover Design', 'Format', 'Publish'];
export const APP_NAME = 'Skribly';
export const TOTAL_TOKENS = 300000;

export const TOUR_STEPS = [
  {
    target: '.writing-input',
    content: 'Start writing in the text editor',
    disableBeacon: true,
  },
  {
    target: '.ai-context',
    content: 'Select Goals, Brainstorm, Research or Vocabulary to tailor Writing Assistant responses below.',
    disableBeacon: true,
  },
  {
    target: '.ai-chat-input',
    content: 'Message AI Writing Assistant',
    disableBeacon: true,
  },
  {
    target: '.book-name',
    content: 'Double click to edit book name',
    disableBeacon: true,
  },
  {
    target: '.front-matter',
    content: 'Add Front Matter components using ‘Add New Section’ button',
    disableBeacon: true,
  },
  {
    target: '.chapters-matter',
    content: 'Add a new chapter using ‘Add New Chapter’ button',
    disableBeacon: true,
  },
  {
    target: '.chapters-matter',
    content: 'Double click to edit chapter, section name',
    disableBeacon: true,
  },
  {
    target: '.back-matter',
    content: 'Add Back Matter components using ‘Add New Section’ button',
    disableBeacon: true,
  },
  {
    target: '.save-button',
    content: 'Click the ‘Save’ button to save your work',
    disableBeacon: true,
  },
  {
    target: '.upload-button',
    content:
      'Click the ‘Upload’ button to upload your work. Only .docx file type supported. Heading & title formatted text will be uploaded as chapters.',
    disableBeacon: true,
  },
];
