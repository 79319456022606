import { BrowserRouter } from 'react-router-dom';
import Navigation from './Navigations/Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NetworkConfig } from './services/apiConfig';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from './Store/store';
import { Offline, Online } from 'react-detect-offline';
import ScrollToTop from './Components/ScrollToTop';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './Components/ErrorFallback';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

let persistor = persistStore(store);
const queryClient = new QueryClient();

NetworkConfig();

const App = () => {
  return (
    <div className="background">
      <Offline>
        <h4
          className="text-center mt-5"
          style={{
            textShadow: '-1px -1px 0 cyan, 1px 1px 0 magenta',
            textAlign: 'center',
          }}
        >
          You're offline right now. Check your connection.
        </h4>
      </Offline>

      <Online>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <ScrollToTop>
                    <Navigation />
                  </ScrollToTop>
                </BrowserRouter>
              </QueryClientProvider>
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      </Online>
    </div>
  );
};
export default App;
