import React, { Suspense, lazy } from 'react';

//React-router-dom
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';

//Navbar
import Navbar from '../Components/Navbar';

//loader
import FullScreenLoader from '../Components/FullScreenLoader';
import { isEmpty } from 'lodash';
import { getLocalStorage } from 'src/utils';

const Home = lazy(() => import('../pages/Writing'));
const Login = lazy(() => import('../pages/Login'));
const SignUp = lazy(() => import('../pages/SignUp'));
const LandingPage = lazy(() => import('../pages/landingPage'));
const Landing = lazy(() => import('../pages/Landing'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));

const ProtectedRoutes = () => {
  if (isEmpty(getLocalStorage('token'))) {
    return <Navigate to="/login" replace />;
  } else {
    return <Outlet />;
  }
};

const PublicRoutes = () => {
  if (!isEmpty(getLocalStorage('token'))) {
    return <Navigate to={`/books`} replace />;
  } else {
    return <Outlet />;
  }
};

const Navigation = () => {
  const location = useLocation();
  return (
    <>
      <Navbar />
      <Suspense fallback={<FullScreenLoader />}>
        <Routes location={location} key={location.pathname}>
          <Route element={<PublicRoutes />}>
            <Route path="/login" element={<Login />} />
            <Route path="/signupforadmin" element={<SignUp />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route path="/" element={<Landing />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path="/books" element={<LandingPage />} />
            <Route path="/writing/:bookId" element={<Home />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
};

export default Navigation;
