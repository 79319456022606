import axios from 'axios';

export const login = (obj) => {
  return axios.post(`/login`, obj);
};

export const signUp = (obj) => {
  return axios.post(`/registration`, obj);
};

export const writingSuggestions = (userId, obj) => {
  return axios.post(`/writing_suggestions/${userId}`, obj);
};

export const getContent = (userId, bookId) => {
  return axios.get(`/get_content/${userId}/book/${bookId}`);
};

export const saveContent = (userId, bookId, body) => {
  return axios.post(`/save_content/${userId}/book/${bookId}`, body);
};

export const getBooksList = (userId) => {
  return axios.get(`/get_books_list/${userId}`);
};

export const createBook = (userId, body) => {
  return axios.post(`/create_book/${userId}`, body);
};
export const deleteBook = (userId, bookId) => {
  return axios.delete(`/delete_book/${userId}/book/${bookId}`);
};

export const getAiTokens = (userId) => {
  return axios.get(`/get_ai_tokens/${userId}`);
};
