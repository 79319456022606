import { clearLocalStorage } from 'src/utils';

const errorResponseHandler = (error) => {
  if (error.response.status === 401) {
    window.location.reload();
    clearLocalStorage('userId');
    clearLocalStorage('token');
  }
  return Promise.reject(error);
};

export default errorResponseHandler;
