import Swal from 'sweetalert2';

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const clearLocalStorage = (key) => {
  return localStorage.removeItem(key);
};

export const Alert = ({ title, text, icon }) => {
  return Swal.fire({
    title: title || 'Something went wrong!',
    text: text || '',
    icon: icon || 'error',
    timer: 3000,
  });
};

export const deleteAlert = async (title, callback) => {
  const result = await Swal.fire({
    title: `Are you sure you want to delete ${title}?`,
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
  });
  if (result.isConfirmed) {
    callback().then(() => {
      Swal.fire({
        title: 'Deleted!',
        text: `Your ${title} has been deleted.`,
        icon: 'success',
      });
    });
  }
};

export const extractHeadingsAndContent = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Select H1, H2, and H3 headings
  const headings = doc.querySelectorAll('h1, h2, h3');
  const result = [];

  if (headings.length === 0) {
    // If there are no headings, create a dummy chapter with all content
    result.push({ title: 'Chapter 1', text: doc.body.innerHTML });
  } else {
    headings.forEach((heading) => {
      const headingText = heading.textContent;
      let content = '';
      let nextSibling = heading.nextSibling;

      while (nextSibling && !['H1', 'H2', 'H3'].includes(nextSibling.nodeName)) {
        content += nextSibling.outerHTML || nextSibling.textContent;
        nextSibling = nextSibling.nextSibling;
      }

      result.push({ title: headingText, text: content });
    });
  }

  return result;
};
