export const styles = {
  navbarFont: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    color: 'black',
    align: 'center',
  },
  NavbarStyle: {
    position: 'static',
    minHeight: '75px',
    width: '100%',
    left: '0px',
    right: '0px',
    top: '0px',
    background: '#fff',
    borderBottom: '1px solid #F1F1F1',
    zIndex: 1,
  },
  uploadButton: {
    border: 'none',
    background: '#d9d9d9',
    paddingInline: '12px',
    paddingBlock: '5px',
    borderRadius: '3px',
  },
};
