import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { login } from '../../services/api/apiServices/auth';

export const loginUser = createAsyncThunk('LOGIN_USER', async (credentials) => {
  try {
    const { data } = await login(credentials);
    return data;
  } catch (error) {
    throw error?.response?.data;
  }
});

const initialState = {
  loading: false,
  error: null,
  authDetails: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state = initialState;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.authDetails = {
        userId: payload?.id,
        token: payload?.token,
        refreshToken: payload?.refreshToken,
        onboarded: payload?.onboarded,
      };
      state.loading = false;
      state.error = null;
    },
    [loginUser.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error.message;
    },
  },
});

export const { logout } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
