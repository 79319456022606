import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import { styles } from '../styles/styles';
import '../styles/styles.css';
import { isEmpty, map } from 'lodash';
import { APP_NAME, NAVBAR_BUTTONS } from 'src/constants/Constants';
import React from 'react';
import { clearLocalStorage, getLocalStorage } from 'src/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const NavigationBar = () => {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = React.useState(NAVBAR_BUTTONS[0]);
  const [showModal, setShowModal] = React.useState(false);
  const { pathname } = useLocation();

  const handleLogout = () => {
    setShowModal(false);
    clearLocalStorage('token');
    clearLocalStorage('userId');
    navigate('/login');
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const HIDDEN_NAVBAR = ['/books'];

  return (
    <>
      {pathname !== '/' && (
        <Navbar collapseOnSelect expand="lg" style={styles.NavbarStyle}>
          <Container fluid>
            <Navbar.Brand onClick={() => navigate('/')} className="pointer">
              {/* <img
              src={require("../assets/logo.png")}
              style={styles.NavbarLogo}
              className="d-inline-block align-top"
            /> */}
              {APP_NAME}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {!isEmpty(getLocalStorage('token')) && !HIDDEN_NAVBAR.includes(pathname) && (
                <Nav className="ms-auto align-items-center CollapsedNavbar">
                  <div className="navbar-button-row">
                    {map(NAVBAR_BUTTONS, (item, idx) => (
                      <div
                        className={selectedPage === item ? 'selected-navbar-button' : 'navbar-button'}
                        key={idx}
                        // onClick={() => setSelectedPage(item)}
                        onClick={() => {
                          if (item !== NAVBAR_BUTTONS[0])
                            Swal.fire({
                              title: 'Coming Soon!',
                              icon: 'error',
                              timer: 3000,
                            });
                        }}
                        style={{ width: '170px', textAlign: 'center' }}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </Nav>
              )}
              <Nav className="ms-auto align-items-center CollapsedNavbar">
                {!isEmpty(getLocalStorage('token')) ? (
                  <Nav.Link className="mx-3" onClick={() => setShowModal(true)}>
                    <span style={styles.navbarFont}>Logout</span>
                  </Nav.Link>
                ) : (
                  <Nav.Link className="mx-3" onClick={() => navigate('/login')}>
                    <span style={styles.navbarFont}>Login</span>
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Make sure you have saved all changes!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NavigationBar;
